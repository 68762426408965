import React from "react";
import Layout from "@/components/layout";
import HeaderOne from "@/components/header-one";
import MenuContextProvider from "@/context/menu-context";
import PageHeader from "@/components/page-header";
import ContactThree from "@/components/contact-three";
import ContactMap from "@/components/contact-map";
import Footer from "@/components/footer";

const Success = () => {
  return (
    <MenuContextProvider>
      <Layout PageTitle="Contact Page">
        <HeaderOne />
        <PageHeader title="Contactos" name="Contact" />
          <section className="contact_form_area contact_us section_padding">
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <div className="contact-right contact_details">
                    <div className="hero-title-with-shape">
                      <h4 className="heading_with_border">Entre em Contacto</h4>
                      <h1>Visitas para orçamentos gratuitas 6 dias por semana</h1>
                    </div>
                    <p>

                    </p>
                    <div className="contact_location_box">
                      <div className="contact_location_map">
                        <span className="icon-location"></span>
                        <p>
                        Rua Do Carmo 185A <br />
                        2785-271 São Domingos de Rana
                        </p>
                      </div>
                      <div className="contact_location_map contact_location_call">
                        <span className="icon-contact_call"></span>
                        <p>
                          geral@macuti.pt <br />
                          969720722
                          917076636
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="contact-right contact-right-style-2 responsive_mt">
                    Recebemos o seu contacto.
                  </div>
                </div>
              </div>
            </div>
          </section>
          <ContactMap />
        <Footer />
      </Layout>
    </MenuContextProvider>
  );
};

export default Success;
