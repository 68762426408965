import React from "react";

const ContactMap = () => {
  return (
    <div className="map_section">
      <div id="map">
        <iframe
          title="macuti"
          src="https://maps.google.com/maps?q=Macuti+Ar+Condicionado+e+Ventilação+Lda.&t=&z=15&ie=UTF8&iwloc=&output=embed"     
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
};

export default ContactMap;
